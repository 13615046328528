const companyList = [
  {
    src: 'https://static.mlytics.com/images/website/logo_customer_pleague.png',
    alt: 'pleague'
  },
  {
    src: 'https://static.mlytics.com/images/website/logo_customer_aurora.png',
    alt: 'aurora'
  },
  {
    src: 'https://static.mlytics.com/images/website/logo_customer_edifian.png',
    alt: 'edifian'
  },
  {
    src: 'https://static.mlytics.com/images/website/logo_customer_pride_toronto.png',
    alt: 'prideToronto'
  },
  {
    src: 'https://static.mlytics.com/images/website/logo_customer_everYoung.png',
    alt: 'everYoung'
  },
  {
    src: 'https://static.mlytics.com/images/website/logo_customer_gis.png',
    alt: 'gis'
  },
  {
    src: 'https://static.mlytics.com/images/website/logo_customer_goingCloud.png',
    alt: 'goingCloud'
  },
  {
    src: 'https://static.mlytics.com/images/website/logo_customer_konica.png',
    alt: 'konica'
  },
  {
    src: 'https://static.mlytics.com/images/website/logo_customer_yile.png',
    alt: 'yile'
  },
  {
    src: 'https://static.mlytics.com/images/website/logo_customer_trinax.png',
    alt: 'trinax'
  },
  {
    src: 'https://static.mlytics.com/images/website/logo_customer_twtc.png',
    alt: 'twtc'
  },
  {
    src: 'https://static.mlytics.com/images/website/logo_customer_retens.png',
    alt: 'retens'
  },
  {
    src: '/images/logo_customer_Telexpress.png',
    alt: 'Telexpress'
  },
  {
    src: '/images/logo_customer_TCI.png',
    alt: 'TCI'
  },
  {
    src: '/images/logo_customer_yuanta.png',
    alt: 'Yuanta Bank'
  },
  {
    src: '/images/logo_customer_Advantech.png',
    alt: 'Advantech'
  }
]

export default companyList
