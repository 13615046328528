import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import Icon from 'components/icon'

const CouponText = ({ text = '' }) => (
  <div className="flex items-center leading-7 text-blue-300">
    <Icon className="mr-2" fontSize="18px" name="verified" />
    <span className="font-bold text-lg lg:text-sm">{text}</span>
  </div>
)

const Coupon = () => {
  const { t } = useTranslation()

  return (
    <div>
      <div className="lg:grid" style={{ gridArea: '1/1' }}>
        <img
          src="https://static.mlytics.com/images/website/coupon-m.png"
          alt="coupon"
          className="block lg:hidden"
          style={{ maxWidth: 320 }}
        />
        <img
          src="https://static.mlytics.com/images/website/coupon.png"
          alt="coupon"
          className="hidden lg:block"
          style={{ gridArea: '1/1', maxWidth: 480, minWidth: 400 }}
        />
        <div className="flex items-end relative" style={{ gridArea: '1/1' }}>
          <div className="flex items-center mb-9 ml-7">
            <div>
              <CouponText text={t('getDemo.coupon.feature1')} />
              <CouponText text={t('getDemo.coupon.feature2')} />
            </div>
            <img
              src="https://static.mlytics.com/images/website/coupon-badge.png"
              alt="coupon"
              className="hidden lg:block ml-1"
              style={{ height: 80, width: 80 }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

CouponText.propTypes = {
  text: PropTypes.string
}

export default Coupon
